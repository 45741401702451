import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithEmailLink as signInWithLink,
  signInWithCustomToken,
  signOut as signout,
  sendPasswordResetEmail as sendPassReset,
  verifyPasswordResetCode as verifyPassReset,
  updatePassword as updatePass,
  User,
  UserCredential,
} from "firebase/auth";

export type { User };

import { UsersAPI, MapsAPI } from "./API";
import { getEulaDoc } from "./Firestore";

import {
  StateDispatch,
  Action,
  Authenticated,
  UserConfig,
  isUserConfig,
} from "../provider/StateProvider";

import { cloneDeep } from "lodash";

import {
  isUserClaims,
  UserClaims,
  UserDoc,
} from "../misc/Types";

import { initFirebase } from "../setup/Setup";
initFirebase();

const auth = getAuth();

const baseActionCodeSettings = {
  url: `${window.location.hostname}/`,
  handleCodeInApp: true
};

const newUserQueryArgs = "?newUser=true";

function refreshAuth(dispatch: StateDispatch): Promise<null | User> {
  dispatch({ action: Action.OnLoadingStart });

  return onAuthStateChanged().then(user => {
    // If theres no user, trigger a signout to make
    // sure all auth info is cleared
    if (!user) {
      dispatch({ action: Action.SignOut, endLoading: true });
      return null;
    }

    // otherwise, refresh the user, tell the provider to
    // load its localstate, then return the user.
    user.reload();
    dispatch({ action: Action.RefreshAuth, user: user, endLoading: true });
    return user;
  });
}

function sendEmailSignInLink(email: string, newUser: boolean): Promise<void> {
  const actionCodeSettings = cloneDeep(baseActionCodeSettings);

  if (newUser) {
    actionCodeSettings.url = actionCodeSettings.url + newUserQueryArgs;
  }

  return sendSignInLinkToEmail(auth, email, actionCodeSettings);
}

function checkEmailSignInLink(url: string): boolean {
  return isSignInWithEmailLink(auth, url);
}

function onAuthStateChanged(): Promise<User | null> {
  return new Promise((resolve, reject) => {
    try {
      auth.onAuthStateChanged(user => resolve(user),
        error => reject(error));
    } catch (err) {
      reject(err);
    }
  });
}

function setAuthPersistence(): Promise<void> {
  return setPersistence(auth, browserSessionPersistence)
    .catch((err) => console.log("setPersistence Error:", err));
}

function onSignOutSuccess(
  dispatch: StateDispatch,
  endLoading: boolean = true,
): Promise<void> {
  if (endLoading) {
    dispatch({ action: Action.SignOut, endLoading: true });
  } else {
    dispatch({ action: Action.SignOut });
  }
  return Promise.resolve();
}

// Gets the map document Ids for all maps this user can see. If the user is
// an admin, true will be returned, indicating they can see all maps.
function extractMaps(userDoc: UserDoc): true | string[] {
  if (userDoc.admin === true) {
    return true;
  }

  const availableMaps: string[] = [];

  Object.entries(userDoc.mapPermissions).forEach(([mapId, mapPerm]) => {
    if (mapPerm) availableMaps.push(mapId);
  });

  return availableMaps;
}

type PermDocs = Pick<UserConfig, "userDoc" | "maps">;


function loadUserAndMapDocs(uid: string): Promise<null | PermDocs> {
  return UsersAPI.getById(uid).then(userDoc => {
    return userDoc !== null
      ? MapsAPI.getByIds(extractMaps(userDoc)).then(maps => ({ userDoc, maps }))
      : null;
  });
}


async function logSignIn(cred: UserCredential): Promise<void> {
  if (!cred.user) {
    return;
  }


  cred.user.uid
}


function completeSignIn(
  userCred: UserCredential,
  dispatch: StateDispatch,
  endLoading: boolean = true,
): Promise<null | Authenticated> {

  if (userCred.user) {
    // kick it off, but dont wait on it
    // logSignIn(userCred).catch(console.warn);

    const promises = [
      loadUserAndMapDocs(userCred.user.uid),
      getUserClaims(userCred.user),
      getEulaDoc()
    ] as const;

    return Promise.all(promises).then(([permDocs, userClaims, eula]) => {
      console.log({ permDocs, userClaims, eula });
      if (permDocs === null || eula === null) {
        return null;
      }

      const config: Record<string, unknown> = {
        userDoc: permDocs.userDoc,
        maps: permDocs.maps,
        eula,
        userClaims: userClaims ?? undefined,
        admin: userClaims?.admin ?? permDocs.userDoc.admin,
      };

      if (isUserConfig(config)) {
        const auth = { user: userCred.user, config };
        if (endLoading) {
          dispatch({ action: Action.SignIn, ...auth, endLoading: true });
        } else {
          dispatch({ action: Action.SignIn, ...auth });
        }
        return auth;
      }

      return null;
    });
  }

  return Promise.resolve(null);
}

function signInWithEmailPass(
  dispatch: StateDispatch,
  email: string,
  password: string,
  endLoading: boolean = true,
): Promise<null | Authenticated> {
  dispatch({ action: Action.OnLoadingStart });

  return setAuthPersistence()
    .then(() => signInWithEmailAndPassword(auth, email, password))
    .then(userCred => completeSignIn(userCred, dispatch, endLoading));
}

function signInWithEmailLink(
  dispatch: StateDispatch,
  email: string,
  emailLink: string,
  endLoading: boolean = true,
): Promise<null | Authenticated> {
  dispatch({ action: Action.OnLoadingStart });

  return setAuthPersistence()
    .then(() => signInWithLink(auth, email, emailLink))
    .then(userCred => completeSignIn(userCred, dispatch, endLoading));
}

function signInWithToken(
  dispatch: StateDispatch,
  token: string,
  endLoading: boolean = true,
): Promise<null | Authenticated> {
  dispatch({ action: Action.OnLoadingStart });

  return setAuthPersistence()
    .then(() => signInWithCustomToken(auth, token))
    .then(userCred => completeSignIn(userCred, dispatch, endLoading));
}

function signOut(
  dispatch: StateDispatch,
  endLoading: boolean = true,
): Promise<void> {
  dispatch({ action: Action.OnLoadingStart });
  return signout(auth).then(() => onSignOutSuccess(dispatch, endLoading));
}

function sendPasswordResetEmail(email: string): Promise<void> {
  return sendPassReset(auth, email);
}

function verifyPasswordResetCode(code: string): Promise<string> {
  return verifyPassReset(auth, code);
}

function getUserClaims(
  user: User,
  forceRefresh: boolean = true,
): Promise<null | UserClaims> {
  return user.getIdTokenResult(forceRefresh)
    .then(res => isUserClaims(res.claims) ? res.claims as UserClaims : null)
    .catch(error => {
      console.error(error);
      return null;
    });
}

function updatePassword(dispatch: StateDispatch, password: string): Promise<void> {
  return refreshAuth(dispatch).then(user => {
    if (user === null) {
      throw new Error(
        "Login credentials exipired, please sign in again to refresh"
      );
    }

    return updatePass(user, password);
  });
}

const FirebaseAuth = {
  refreshAuth: refreshAuth,
  signInWithToken: signInWithToken,
  signInWithEmailPass: signInWithEmailPass,
  signOut: signOut,
  sendPasswordResetEmail: sendPasswordResetEmail,
  verifyPasswordResetCode: verifyPasswordResetCode,
  sendEmailSignInLink: sendEmailSignInLink,
  checkEmailSignInLink: checkEmailSignInLink,
  signInWithEmailLink: signInWithEmailLink,
  updatePassword,
  getToken: function(): Promise<string | null> {
    return onAuthStateChanged().then(user => user?.getIdToken(true) ?? null);
  },
};

export default FirebaseAuth;

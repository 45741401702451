import React, { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import Map from "./../components/map/Map";
import { caseInsensitiveIncludes } from "../misc/Utils";
import { useStateContext } from "../provider/StateProvider";

import { MapsAPI } from "../firebase/API";

import MapManager from "../map/MapManager";
import cloneDeep from "lodash/cloneDeep";

import * as Sentry from "@sentry/react";

import type { MapDoc } from "../misc/Types";
import TopNav from "../components/nav/TopNav";

const mysti_logo = new URL("/public/static/images/mysticetus_logo.png", import.meta.url);


function MapView() {
  const mapSlug = useMatch("/map/:slug")?.params?.slug ?? null;

  const state = useStateContext();

  const [mapDocument, setMapDoc] = useState<MapDoc|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [mapManager, setMapManager] = useState<MapManager|null>(null);


  useEffect(() => {
    if (state.user && state.config && mapSlug) {
      const mapKeys = Object.keys(state.config.maps);
      const matchedMap = caseInsensitiveIncludes(mapKeys, mapSlug);

      if (typeof matchedMap === "string") {
        MapsAPI.getById(matchedMap).then(mapDoc => {
          if (mapDoc) {
            setMapDoc(mapDoc);
          }

          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }

    }

    return () => {
      setMapDoc(null);
    };
  }, []);

  useEffect(() => {
    if (mapDocument && !mapManager) {
      setMapManager(new MapManager(cloneDeep(mapDocument), state.config?.admin));
    }
  }, [mapDocument]);

  return (
    <>
      {
        isLoading
          ? <p>Loading</p>
          : mapDocument && mapManager
            ? <Map mapDocument={mapDocument} mapManager={mapManager}/>
            : () => {
              Sentry.captureEvent({
                message: "unknown map slug/missing permissions",
                timestamp: Date.now() / 1000,
                breadcrumbs: [{
                  data: {
                    windowLocation: window.location,
                    state: state,
                  }
                }]
              });
              return (
                <>
                  <TopNav />
                  <b className="mt-4">
                    This map does not exist, or you lack the permissions to view it.
                  </b>
                </>
              );
            }
      }
      <div className="centered_div_container">
        <div className="centered_div">
          <a id="logo_link" href="https://mysticetus.com" rel="noopener noreferrer" target="_blank">
            <img id="logo" src={mysti_logo.href} alt="Mysticetus"/>
          </a>
        </div>
      </div>
    </>
  );
}

export default MapView;

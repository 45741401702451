import {
  Mapbox,
  PopupRef,
  MapDoc,
  MapMenuConfig,
  FeatureType,
} from "../misc/Types";

import MapSetup from "./MapSetupV3";


import { ClientFilter, IconName, IconResult, LayerConfig, LayerParamOptions, LayerType } from "../layers/LayerTypes";
import L from "../layers/groups";

import DataCache from "./DataCache";
import { Geo, GeoType } from "../misc/Geo";
import { GeoJson, emptyFeatureCollection } from "../misc/GeoJson";

import { extractMapSourcesByClient, MapSourcesByClient } from "../firebase/Firebase";
import API from "../firebase/API";

import {
  calculateZoomFromBounds,
  getBoundingCoordinatesOfFeatures,
  averageVectors,
  normVectorFromLatLonPoint,
  latLonFromNormVector,
} from "../misc/GeoUtils";

import { PaginatedGeoJsonQuery } from "../firebase/Firestore";
import { cloneDeep, debounce, partial, throttle } from "lodash";

import * as Sentry from "@sentry/react";
import { RGB } from "./Colors";
import { Layer, MiscLayer } from "../layers/LayerTypes";
import { LayerGroup } from "../layers/LayerGroup";
import type { OnLoaded } from "../hooks/useLoadTracker";
import { TimeDelta, TimeOffset } from "../misc/TimeDelta";


// 6 hours in seconds
const defaultTrackTimeDelta: TimeDelta = {
  new: null,
  old: TimeOffset.fromHours(6),
};

const defaultSightingTimeDelta: TimeDelta = {
  new: null,
  old: TimeOffset.fromHours(24),
};

const mapboxLayers: string[] = ["NOAA-SMA"];

const listenerDataTypes = [
  FeatureType.Ellipses,
  FeatureType.FishingGear,
  FeatureType.Sightings,
  FeatureType.BearingLines
] as const;

type ListenerDataTypes = (typeof listenerDataTypes)[number];

// weird type inference means we need to cast to this impossible 
// type to keep the typechecker happy when adding a feature to 
// a generalized data cache.
type AnyFeature = (
  GeoJson.Feature<GeoType.Point>
  & GeoJson.Feature<GeoType.LineString>
  & GeoJson.Feature<GeoType.Polygon>
);


function handleAddedIconResult(
  icon: IconName,
  map: mapboxgl.Map,
  result: IconResult | Promise<IconResult>,
): void {
  if (result instanceof Promise) {
    result
      .then(innerResult => handleAddedIconResult(icon, map, innerResult))
      .catch(error => console.error(`error loading ${icon.formatIconName()} ${error}`));
  } else {
    // if (window.location.host.includes("localhost")) {
    //   console.log(`${icon.formatIconName()}: ${result}`);
    // }
    
    if (result === IconResult.Added) {
      map.triggerRepaint();
    }
  }
}


class MapManager {
  private _map: null | Mapbox.Map = null;
  private _mapSetup: MapSetup;
  private _admin: boolean = false;

  private _dataCache: DataCache;
  private _timeDeltaMap: { [key in FeatureType]?: TimeDelta } = {
    [FeatureType.FishingGear]: defaultSightingTimeDelta,
    [FeatureType.Sightings]: defaultSightingTimeDelta,
  };

  private _trackTimeDelta: TimeDelta = defaultTrackTimeDelta;

  private _dataListeners: { [key: string]: undefined | (() => void) } = {};
  private _tracklineListeners: {[key: string]: () => void} = {};

  private _runningGeoJsonQueries: {
    [Key in FeatureType]?: null | Promise<boolean>
  } = {};

  private _runningTracklineQuery: null | Promise<void> = null;

  private _imageLoadPromise: Promise<MapMenuConfig>;

  private _sightingStateInterval: null | NodeJS.Timeout = null;

  private _clientFilters: { [Key in keyof Layer]?: null | ClientFilter } = {};

  private _clientColorMapping: Record<string, RGB> = {};


  public mapDocument: MapDoc;
  public readonly mapDocSources: MapSourcesByClient;
  public mapInitialized = false;

  constructor(mapDocument: MapDoc, isAdmin?: boolean) {
    this.mapDocument = mapDocument;

    if (isAdmin === true) {
      this._admin = true;
    }

    this.mapDocSources = extractMapSourcesByClient(this.mapDocument);

    this._dataCache = new DataCache({
      initSightingTimeDelta: this._timeDeltaMap.sightings ?? defaultSightingTimeDelta,
      clients: this.mapDocSources.sightings,
      leaseAreaIds: this.mapDocSources.leaseAreas,
    });

    this._mapSetup = new MapSetup(this.mapDocument);

    this._imageLoadPromise = this._mapSetup.loadImages();
  }

  public setupMap = (map: Mapbox.Map, popupRef: PopupRef): Promise<void> => {
    if (this._map === null) {
      this._map = map;
      this._mapSetup.setMap(map);
      // re-set this promise, since it changes internally via the MapSetup.setMap call.
      this._imageLoadPromise = this._mapSetup.loadImages();

      this.initializeMap(popupRef);

      this.mapInitialized = true;

      return this.setup();
    } else {
      console.error("Cannot set a new map to an instance that already has one.");
      return Promise.resolve();
    }
  };

  public setMapLegendChangeHandler = (handler: (curr: MapMenuConfig) => void): void => {
    this._mapSetup.setMapImageChangeHandler(handler);
  };


  public loadMenuConfig = (): Promise<MapMenuConfig> => {
    return this._imageLoadPromise;
  };

  private refreshDataInner = (dataType: FeatureType) => {
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const that = this;
    return (function() {
      // Make sure we can get the mapSource right off the bat,
      // that way we dont waste time getting the data formatted, only
      // to find there's no source to insert the data into.
      const mapSource = that.getMapSource(dataType);

      if (!mapSource) { 
        throw new Error(`Could not get ${dataType} map source`);
      }

      let dataRecord: Record<string, GeoJson.Feature> = {};
      
      // Then depending on the data type, get the appropriate data out of the
      // cache.
      switch (dataType) {
      // Generic data types, where we want to get all of them no matter what.
        case FeatureType.Buoys:
        case FeatureType.Gliders:
        case FeatureType.SlowZones:
          dataRecord = that._dataCache[dataType].getAll();
          break;
        // Lease areas require the map to be configured to show certain ones.
        case FeatureType.LeaseAreas:
          dataRecord = that.mapDocSources.leaseAreas
            ? that._dataCache.leaseAreas.getByIds(that.mapDocSources.leaseAreas)
            : {};
          break;
        // Along with client, we also have maxAge constraints via the map controls
        case FeatureType.Sightings:
        case FeatureType.FishingGear:
        case FeatureType.Ellipses:
        case FeatureType.BearingLines:
          dataRecord = that._dataCache[dataType].getAll({
            clients: that.mapDocSources[dataType],
            maxAge: that._timeDeltaMap[dataType]?.old.delta,
            minAge: that._timeDeltaMap[dataType]?.new?.delta,
          });
          break;
        // For stations, we have client constraints
        case FeatureType.Stations:
          dataRecord = that.mapDocSources.stations
            ? that._dataCache.stations.getByClients(that.mapDocSources.stations)
            : {};
          break;
      }

      // Handle grabbing tracklines for types that have them
      if (dataType === FeatureType.Gliders || dataType === FeatureType.Stations) {
        // use the Ids we have to only get tracklines for valid vehicles
        const trackTimeDelta = that._trackTimeDelta ?? defaultTrackTimeDelta;

        let trackCoords: null | Geo.Coordinate[];
        let trackFeature: GeoJson.LineStringFeature;
        let vehicleFeature: GeoJson.PointFeature;
        for (const id in dataRecord) {
          trackCoords = that._dataCache.tracklines.getById(id, trackTimeDelta);

          // Skip if this vehicle doesn't have points.
          if (!trackCoords || trackCoords.length === 0) { 
            continue;
          }

          vehicleFeature = cloneDeep(dataRecord[id]);

          // Make sure we have the vehicle coords as the last point on the
          // trackline, that way the line is always "attached"
          trackCoords.push(vehicleFeature.geometry.coordinates);

          // Use the vehicle properties as a base, then remove what we dont want
          trackFeature = {
            type: "Feature",
            geometry: {
              coordinates: trackCoords,
              type: GeoType.LineString,
            },
            properties: vehicleFeature.properties,
          };

          // override the color to match the vehicle.
          trackFeature.properties.color = that._mapSetup.getClientColor(
            vehicleFeature.properties.client
          );

          // kill the displayProperties, and add trackline to the name
          delete trackFeature.properties.displayProperties;
          trackFeature.properties.name += " Trackline";

          // Add the feature with an extended Id to not clobber the parent vehicle
          dataRecord[id + "-trackline"] = trackFeature;
        }
      }

      mapSource.setData({
        type: "FeatureCollection",
        features: Object.values(dataRecord),
      });
    }());
  };

  private refreshData = {
    [FeatureType.BearingLines]: throttle(partial(this.refreshDataInner, FeatureType.BearingLines), 100, { leading:true }),
    [FeatureType.Buoys]: throttle(partial(this.refreshDataInner, FeatureType.Buoys), 100, { leading:true }),
    [FeatureType.Ellipses]: throttle(partial(this.refreshDataInner, FeatureType.Ellipses), 100, { leading:true }),
    [FeatureType.Gliders]: throttle(partial(this.refreshDataInner, FeatureType.Gliders), 100, { leading:true }),
    [FeatureType.LeaseAreas]: throttle(partial(this.refreshDataInner, FeatureType.LeaseAreas), 100, { leading:true }),
    [FeatureType.Sightings]: throttle(partial(this.refreshDataInner, FeatureType.Sightings), 100, { leading:true }),
    [FeatureType.FishingGear]: throttle(partial(this.refreshDataInner, FeatureType.Sightings), 100, { leading: true }),
    [FeatureType.SlowZones]: throttle(partial(this.refreshDataInner, FeatureType.SlowZones), 100, { leading:true }),
    [FeatureType.Stations]: throttle(partial(this.refreshDataInner, FeatureType.Stations), 100, { leading:true }),
  };

  private tryRefreshData = (dataType: FeatureType, cancel = false) => {
    if (cancel) {
      this.refreshData[dataType].cancel();
    }

    this.refreshData[dataType]();
  };

  // At the moment, this encompasses only lease areas.
  private loadOneTimeData = (dataType: FeatureType.LeaseAreas, ids: true | string[]): Promise<void> => {
    const api = API[dataType];
    const omitIds: undefined | string[] = this._dataCache[dataType]?.getIds();

    if (!api) return Promise.resolve();

    const loadProm = Array.isArray(ids)
      ? api.getByIds(ids, { omitIds: omitIds })
      : api.getAll({ omitIds: omitIds });

    return loadProm.then(dataRecord => {
      // add the new data to the cache
      this._dataCache[dataType].addOrUpdate(dataRecord);

      // console.log('leaseAreas', dataRecord);

      // Then, trigger an update to show the correct data
      this.tryRefreshData(dataType, true);
    }).catch(err => {
      Sentry.captureException(err);
    });
  };

  private genericListenerCallbackFactory = (dataType: FeatureType): (rec: Record<string, GeoJson.Feature>) => void => {
    if (dataType === FeatureType.Gliders) {
      return (record: Record<string, GeoJson.Feature>) => {
        for (const id in record) {
          if (!this._tracklineListeners[id]) {
            this.setUpTrackline(id).then(newData => {
              // request an update if we got data
              if (newData) this.tryRefreshData(dataType, true);
            });
          }

          this._dataCache[dataType].addOrUpdate(id, record[id]);
        }

        // console.log(`recieved new ${dataType} data`);

        this.tryRefreshData(dataType);
      };
    } else {
      return (record: Record<string, GeoJson.Feature>) => {       
        const cache = this._dataCache[dataType];

        

        for (const [id, feature] of Object.entries(record)) {
          const color = this._mapSetup.getClientColor(feature.properties.client);
          feature.properties.color = color;
          
          cache.addOrUpdate(id, feature as AnyFeature);
        }

        this.tryRefreshData(dataType);
      };
    }
  };

  private setUpGenericListener = (
    dataType: FeatureType,
  ): void => {
    // If we already have them set up, bail.
    if (this._dataListeners[dataType]) return;

    const callback = this.genericListenerCallbackFactory(dataType);

    if (dataType === FeatureType.FishingGear) {
      return;
    }

    if (dataType === FeatureType.SlowZones || dataType == FeatureType.LeaseAreas) {
      this._dataListeners[dataType]
        = API[dataType].listenToCollection(callback, undefined);
    } else {
      const api = API[dataType];

      if ("listenWithUniversalArgs" in api) {
        const sources = this.mapDocSources[dataType] ?? [];

        this._dataListeners[dataType]
          = api.listenWithUniversalArgs(dataType as FeatureType.BearingLines, callback, sources);
      }
    }
  };

  private setUpNewGeoJsonListener = (dataType: ListenerDataTypes): void => {
    const clients = this.mapDocSources[dataType];
    if (!clients) {
      return;
    }

    this._dataListeners[dataType] = API[dataType].listenToNew((id, feat) => {
      const color = this._mapSetup.getClientColor(feat.properties.client);
      feat.properties.color = color;
      this._dataCache[dataType].addOrUpdate(id, feat);
      this.tryRefreshData(dataType);
    }, { clients });
  };


  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  private loadGeoJsonWrapper = (
    dataType: ListenerDataTypes,
    onLoaded?: OnLoaded,
    update = false,
  ): Promise<boolean> => {
    // Bail if we cant see sightings
    if (!this.mapDocSources[dataType]) { 
      return Promise.resolve(false);
    }
    
    // workaround to prevent bearing lines from appearing
    // if (dataType === DataType.BearingLines) return Promise.resolve(false);

    // If we already have a running query, wait for it to complete,
    // then call again, that way we can tailor the start/stop times
    // to account for the newly loaded data\
    const runningQuery = this._runningGeoJsonQueries[dataType];
    if (runningQuery instanceof Promise) {
      return runningQuery
        .then(() => this.loadGeoJsonInner(dataType, onLoaded))
        .then(innerUpdate => {
          if (innerUpdate || update) {
            return this._dataCache.updateCache(dataType).then(() => {
              this.refreshDataInner(dataType);
              return false;
            });
          }
          return false;
        })
        .catch(error => {
          console.error(error);
          Sentry.captureException(error);
          return false;
        });
    } else {
      this._runningGeoJsonQueries[dataType] = this.loadGeoJsonInner(dataType, onLoaded)
        .then(innerUpdate => {
          if (innerUpdate || update) {
            return this._dataCache.updateCache(dataType).then(() => {
              this.refreshDataInner(dataType);
              return false;
            });
          }
          return false;
        })
        .catch(error => {
          console.error(error);
          Sentry.captureException(error);
          return false;
        });

      return this._runningGeoJsonQueries[dataType] as Promise<boolean>;
    }
  };

  private loadGeoJsonInner = (
    dataType: ListenerDataTypes,
    onLoaded?: OnLoaded,
  ): Promise<boolean> => {
    // Bail if we cant see the data type
    const clients = this.mapDocSources[dataType];
    if (!clients) return Promise.resolve(false);

    let timeDelta = this._timeDeltaMap[dataType] ?? defaultSightingTimeDelta;
    if (dataType === FeatureType.FishingGear) {
      timeDelta = this._timeDeltaMap[FeatureType.Sightings] ?? defaultSightingTimeDelta;
    }

    // Default opts
    const now = Date.now() / 1000;

    let endAtEpoch: number | undefined = undefined;

    if (timeDelta.new) {
      endAtEpoch = now - timeDelta.new.delta;
    }

    const opts: PaginatedGeoJsonQuery = {
      clients,
      omitIds: this._dataCache[dataType]?.getIds() ?? undefined,
      startAtEpoch: now - timeDelta.old.delta,
      endAtEpoch,
      paginationLimit: 500,
    };

    // Get sighting bounds so we know to only query a range of ages
    const cachedBounds = this._dataCache[dataType]
      .getCacheBoundsByClients(clients);

    if (cachedBounds && cachedBounds.min) {
      opts.endAtEpoch = cachedBounds.min;
    }

    // Skip actually querying if we have data earlier than our cutoff
    if (opts.endAtEpoch &&
        opts.startAtEpoch &&
        opts.startAtEpoch > opts.endAtEpoch
    ) {  
      onLoaded && onLoaded();
      return Promise.resolve(true);
    }

    let featuresLoaded = 0;
    const cache = this._dataCache[dataType];

    const sightingHandler = (id: string, feature: GeoJson.Feature) => {
      const color = this._mapSetup.getClientColor(feature.properties.client);
      feature.properties.color = color;
      cache.addOrUpdate(id, feature as AnyFeature);
      featuresLoaded += 1;

      if (featuresLoaded % (opts.paginationLimit ?? 250) === 0) {
        this.refreshDataInner(dataType);
      }
    };

    this._runningGeoJsonQueries[dataType] = API[dataType]
      .paginatedQuery(opts, sightingHandler, onLoaded)
      .then(resp => {
        this.refreshDataInner(dataType);

        // console.log(`sighting request loaded ${sightingCount}`);
        if (resp instanceof Error) {
          console.error(resp);
        }
        return true;
      })
      .finally(() => onLoaded && onLoaded());

    return this._runningGeoJsonQueries[dataType] as Promise<boolean>;
  };

  // throttle(
  //, 250, { leading: true });
  private loadGeoJson = this.loadGeoJsonWrapper;

  private setUpTrackline = (vehicleId: string): Promise<boolean> => {
    const now = Date.now() / 1000;
    if (!this._tracklineListeners[vehicleId]) {
      const opts = {
        id: vehicleId,
        startEpoch: now,
      };

      const unsub = API.tracklines.listen((id, trackPoint) => {
        this._dataCache.tracklines.addNewPoints(id, trackPoint);
      }, opts);

      this._tracklineListeners[vehicleId] = unsub;
    }

    const trackTimeDelta = this._trackTimeDelta ?? defaultTrackTimeDelta;

    const startEpoch = now - trackTimeDelta.old.delta;
    let endEpoch = now - (trackTimeDelta.new?.delta ?? 0);

    const existingStart = this._dataCache.tracklines.getOldestEpoch(vehicleId);

    if (existingStart) {
      // If we already have data older than the current start, we can just use
      // cached values, so bail.
      if (existingStart < startEpoch) {
        return Promise.resolve(false);
      }

      // Then set the end to the end of the data we already have
      endEpoch = existingStart;
    }

    return API.tracklines.getById(vehicleId, startEpoch, endEpoch).then(points => {
      if (!points) return false;

      this._dataCache.tracklines.addNewPoints(vehicleId, points);

      return true;
    });
  };

  private setUpStationListeners = (): Promise<void> => {
    if (this._dataListeners.stations || !this.mapDocSources.stations) {
      return Promise.resolve();
    }

    const unsub = API.stations.listen((id, station) => {
      this._dataCache.stations.addOrUpdate(id, station);

      if (!this._tracklineListeners[id]) {
        this.setUpTrackline(id).then(resp => {
          if (resp) {
            this.tryRefreshData(FeatureType.Stations);
          }
        });
      } else {
        this.tryRefreshData(FeatureType.Stations);
      }
    }, { clients: this.mapDocSources.stations });

    this._dataListeners[FeatureType.Stations] = unsub;

    return API.stations.getByClients(this.mapDocSources.stations).then(stations => {
      this._dataCache.stations.addOrUpdate(stations);
      this.tryRefreshData(FeatureType.Stations, true);
    });
  };

  private getMapSource = (sourceId: FeatureType): null | Mapbox.GeoJsonSource => {
    if (!this._map || !this.mapInitialized) return null;

    const mapSource = this._map.getSource(sourceId) ?? null;

    if (mapSource && mapSource.type === "geojson") {
      return mapSource;
    }

    return null;
  };

  private initializeMap = (popupRef: PopupRef): void => {
    if (!this._map) return;

    let layer: LayerConfig<LayerType.Any>;

    for (const layerId of L.LAYER_ORDER) {
      layer = L.LAYERS[layerId];

      if (!layer) continue;

      // Sources need to be loaded BEFORE the layer
      if (!this._map.getSource(layer.sourceId)) {
        this._map.addSource(layer.sourceId, {
          type: "geojson",
          lineMetrics: true,
          data: emptyFeatureCollection
        });
      }

      this._map.addLayer(layer.factory());

      if ("addIcon" in layer && 
          typeof layer.addIcon === "function" &&
          !layer.shouldLazilyLoadIcons
      ) {

        for (const [client, borderColor] of Object.entries(this._clientColorMapping)) {
          const iconName = new IconName(client, layer.layerId);
          handleAddedIconResult(
            iconName,  
            this._map,
            layer.addIcon({ map: this._map, iconName, borderColor })
          );
        }
      }

      // Set up mouse event handlers for non-polygon layers
      if (layerId !== FeatureType.SlowZones && FeatureType.LeaseAreas) {
        this._map.on("mouseenter", layerId, () => {
          if (!this._map) return;

          this._map.getCanvas().style.cursor = "pointer";
        });

        this._map.on("mouseleave", layerId, () => {
          if (!this._map) return;

          this._map.getCanvas().style.cursor = "";
        });
      }
    }

    const layers: string[] = cloneDeep(L.LAYER_ORDER);

    for (const additionalLayer of mapboxLayers) {
      if (!layers.includes(additionalLayer)) {
        layers.splice(0, 0, additionalLayer);
      }
    }

    const mapSetup = this._mapSetup;
    this._map.on("click", function(args) {
      mapSetup.tryShowInfoPopup(layers, popupRef, args);
    });

    // Only load the expensive to load sighting gifs when they're needed.
    // Loading them after erroring also lets us avoid digging into the
    // data pipeline to find which ones we need, likely slowing it down.
    this._map.on("styleimagemissing", (event) => {  
      const eventId = event.id;

      if (typeof eventId !== "string" || !this._map) {
        return;
      }

      const iconName = IconName.parseIconName(eventId);

      if (iconName) {
        const layer = L.LAYERS[iconName.layer];

        if ("addIcon" in layer && typeof layer.addIcon === "function") {
          const clientColor = this._mapSetup.getClientColor(iconName.client); 

          handleAddedIconResult(
            iconName, 
            this._map,
            layer.addIcon({ map: this._map, iconName, borderColor: clientColor })
          );
        } 
      }
    });
  };

  private initializeData = (): Promise<void> => {
    this.setUpGenericListener(FeatureType.Buoys);
    this.setUpGenericListener(FeatureType.Gliders);
    this.setUpGenericListener(FeatureType.SlowZones);

    const loadPromises: Promise<void>[] = [];

    if (this.mapDocSources.leaseAreas) {
      loadPromises.push(this.loadOneTimeData(FeatureType.LeaseAreas, this.mapDocSources.leaseAreas));
    }
    
    for (const dataType of listenerDataTypes) {
      const sources = this.mapDocSources[dataType];
      if (!sources) continue;

      this.setUpNewGeoJsonListener(dataType);
      const promise = this.loadGeoJsonInner(dataType) as Promise<unknown> as Promise<void>;
      loadPromises.push(promise);
    }

    if (this.mapDocSources.stations) {
      loadPromises.push(this.setUpStationListeners());
    }

    return Promise.all(loadPromises).then(() => {
      this.setDataTimeDelta(
        [FeatureType.Sightings, FeatureType.BearingLines, FeatureType.Ellipses, FeatureType.FishingGear],
        this._timeDeltaMap.sightings ?? defaultSightingTimeDelta,
      );

      // Force the cache to save all the persistent data once
      // we have everything initially loaded.
      return Promise.all([
        this._dataCache.updateCache(),
        this.flyToInitPosition()
      ]);
    }) as Promise<void>;
  };

  private setup = (): Promise<void> => {
    if (!this._map || !this._mapSetup || !this.mapInitialized) {
      Sentry.addBreadcrumb({
        message: "Map not initialized yet",
        timestamp: Date.now() / 1000,
        data: {
          map: this._map,
          mapSetup: this._mapSetup,
          mapDocument: this.mapDocument,
        }
      });
      return Promise.reject(new Error("Map not initialized yet"));
    }

    return this._dataCache.initialize().then(() => {
      for (const sourceId of this._dataCache.persistentCaches) {
        if (sourceId === "tracklines") { 
          continue;
        }

        this.tryRefreshData(sourceId);
      }

      // console.log('Cached data refresh requested');

      if (!this._mapSetup) {
        throw new Error("this._mapSetup is null while running setup");
      }

      const loadPromises = [
        // Load the icons/images we need, and once loaded pass the icons
        // to the map legend.
        this._imageLoadPromise,

        // load all the data, returning the promise so the map page can be told
        // to stop loading.
        this.initializeData(),
      ];

      return Promise.all(loadPromises);
    }) as Promise<void>;
  };

  public flyToInitPosition = (): Promise<void> => {
    // Leaving in case we move to using map.queryRenderedFeatures again
    // const queryArgs = {
    //   filter: ['==', ['geometry-type'], 'Point'],
    //   validate: true // TODO flip to false once dev is finished
    // };
    return Promise.resolve().then(() => {
      if (!this._map) return;

      const queriedSightings =  this._map.queryRenderedFeatures(undefined, {
        layers: [FeatureType.Sightings]
      });

      const queriedStations =  this._map.queryRenderedFeatures(undefined, {
        layers: [FeatureType.Stations]
      });

      const sightings = this._dataCache.sightings.getByIds(
        queriedSightings.map(sgt => sgt.properties?.id)
      );

      const stations = this._dataCache.stations.getByIds(
        queriedStations.map(station => station.properties?.id)
      );

      const features = Object.values({ ...sightings, ...stations });

      // if we have no features, just leave the map in its default position
      if (features.length === 0) return;

      const featBounds = getBoundingCoordinatesOfFeatures(features);

      if (!featBounds) return;

      // If we use just the bounds, the average will be the center
      // We may want to move to a weighted center at some point.
      const boundNormVecs = featBounds.map(normVectorFromLatLonPoint);

      const centerVec = averageVectors(boundNormVecs);
      const center = latLonFromNormVector(centerVec);
      let zoom = calculateZoomFromBounds(featBounds);

      // Prevent zooming in too far, cap at a max zoom of 7 
      // (larger numbers == a higher zoom)
      zoom = Math.min(zoom, 7);

      this._map.flyTo({
        center: center,
        zoom: zoom,
        speed: 1.75,
      // easing: t => t,
      } as Mapbox.FlyToOpts);
    });
  };

  public setSightingClientFilter = (
    innerLayer: Layer,
    clientFilter: null | ClientFilter
  ): void => { 
    if (!this._map) {
      return;
    }


    const timeDelta = this._timeDeltaMap[innerLayer] ?? defaultSightingTimeDelta;

    const layerOpts = { clientFilter, timeDelta };

    for (const layer of L.LAYER_TO_GROUP[innerLayer].iterLayers()) {
      this.updateLayerParams(layer.layerId, layerOpts);
    }
  };

  public setLayerVisibility = (layerId: string, visible: boolean): void => {
    if (!this._map || !this.mapInitialized) { 
      return;
    }

    if (this._map.getLayer(layerId)) {
      const newVis = visible ? "visible" : "none";

      
      const layer: null | LayerConfig<LayerType.Any> = L.LAYERS[layerId] ?? null;
      if (!layer) { 
        return;
      }
      
      const layerGroup: LayerGroup = L.LAYER_TO_GROUP[layerId];
      
      for (const layer of layerGroup.iterLayers()) {
        this._map.setLayoutProperty(layer.layerId, "visibility", newVis);
      }

      if (layerId === FeatureType.SlowZones) {
        this.setLayerVisibility("NOAA-SMA", visible);
      }
    }
  };

  public getLayerVisibility = (layerId: string): boolean | null => {
    if (!this._map || !this.mapInitialized) { 
      return null;
    }

    if (this._map.getLayer(layerId) !== undefined) {
      const visibility = this._map.getLayoutProperty(layerId, "visibility");

      return visibility === "visible";
    }

    return null;
  };

  public updateLayerParams = (layerId: Layer, opts: Partial<LayerParamOptions>): void => {
    if (!this._map || !this.mapInitialized) { 
      return;
    }

    if (typeof opts.visible !== "boolean") {
      opts.visible = this.getLayerVisibility(layerId) ?? true;
    }

    // null means unset
    if (opts.clientFilter === null) {
      this._clientFilters[layerId] = null;
    } else if (opts.clientFilter === undefined) {
      // undefined means use whatever is configured now
      opts.clientFilter = this._clientFilters[layerId];
    } else {
      // if specified, update the config
      this._clientFilters[layerId] = opts.clientFilter;
    }

    // if we don't include the age in every layer param update, 
    // it resets back to the default. 
    if (!opts.timeDelta) {
      opts.timeDelta = this._timeDeltaMap[layerId];
    }

    // Only update if there is an existing layer with the give ID.
    if (this._map.getLayer(layerId) && L.LAYERS[layerId]) {
      const params = L.LAYERS[layerId].factory(opts as LayerParamOptions);
      this._map.setFilter(layerId, params.filter);

      if (params.paint) {
        for (const propName in params.paint) {
          this._map.setPaintProperty(layerId, propName, params.paint[propName]);
        }
      }

      if (params.layout) {
        for (const propName in params.layout) {
          this._map.setLayoutProperty(layerId, propName, params.layout[propName]);
        }
      }

      this._map.triggerRepaint();
    }
  };

  public startTracklineAgeQuery = ({ cancel, onLoaded }: { cancel?: boolean, onLoaded?: OnLoaded }): Promise<void> => {

    const gliderIds = this._dataCache.gliders.getIds();
    const stationIds = this._dataCache.stations.getIds();
     
    const total = gliderIds.length + stationIds.length;

    if (total === 0) {
      return Promise.resolve();
    }
    
    if (cancel) {
      this.refreshData[FeatureType.Stations].cancel();
      this.refreshData[FeatureType.Gliders].cancel();
    }




    const promises: Promise<boolean>[] = new Array(total); 

    let i = 0;
    
    for (const gliderId of gliderIds) {
      promises[i] = this.setUpTrackline(gliderId);
      i += 1;
    }
    
    for (const stationId of stationIds) {
      promises[i] = this.setUpTrackline(stationId);
      i += 1;
    }
    
    return Promise.all(promises).then(shouldRefreshCache => {
      this.tryRefreshData(FeatureType.Stations);
      this.tryRefreshData(FeatureType.Gliders);

      // call now, since we want to run cache stuff in the background.
      onLoaded && onLoaded();

      if (shouldRefreshCache.some(u => u)) {
        return this._dataCache.updateCache("tracklines");
      }
    });
  };

  public getDataTypeTimeDelta = (
    dataType: FeatureType | "tracklines"
  ): null | TimeDelta => {
    if (dataType === "tracklines") {
      return this._trackTimeDelta;
    }

    return this._timeDeltaMap[dataType] ?? null;
  };


  private handleTrackTimeDeltaChange = (timeDelta: TimeDelta, onLoaded?: OnLoaded): void => {
    this._trackTimeDelta = timeDelta;

    const opts = { cancel: true, onLoaded };

    if (this._runningTracklineQuery instanceof Promise) {
      this._runningTracklineQuery.catch(console.error).finally(() => this.startTracklineAgeQuery(opts));
    } else {
      this._runningTracklineQuery = this.startTracklineAgeQuery(opts);
    }
  };


  private handleDataTimeDeltaChange = (sourceId: FeatureType, timeDelta: TimeDelta, onLoaded?: OnLoaded): void => {
    if (sourceId === FeatureType.Sightings) {
      for (const layer of L.LAYER_TO_GROUP.sightings.iterLayers()) {
        this.updateLayerParams(layer.layerId, { timeDelta });
      }

      for (const layer of L.LAYER_TO_GROUP.birdSightings.iterLayers()) {
        this.updateLayerParams(layer.layerId, { timeDelta });
      }

      this._dataCache.setSighingTimeDelta(timeDelta)
        .then(() => Promise.all([
          this.loadGeoJson(sourceId, undefined, true),
          this.loadGeoJson(FeatureType.FishingGear, undefined, true),
        ]))
        .finally(() => onLoaded && onLoaded());

    } else if (sourceId === FeatureType.Ellipses) {
      this.updateLayerParams(FeatureType.Ellipses, { timeDelta });
      this.updateLayerParams(MiscLayer.EllipseOutlines, { timeDelta });
      
      this._dataCache.setSighingTimeDelta(timeDelta)
        .then(() => this.loadGeoJson(sourceId, onLoaded, true))
        .finally(() => {
          this.refreshDataInner(sourceId);
          onLoaded && onLoaded();
        });

    } else if (sourceId === FeatureType.BearingLines) {
      this.updateLayerParams(FeatureType.BearingLines, { timeDelta });
      this.updateLayerParams(MiscLayer.BearingLineOutlines, { timeDelta });
      
      this._dataCache.setSighingTimeDelta(timeDelta)
        .then(() => this.loadGeoJson(sourceId, onLoaded, true))
        .finally(() => {
          this.refreshDataInner(sourceId);
          onLoaded && onLoaded();
        });
    } else {
      // instantly finish if we somehow try this on the wrong data type.
      onLoaded && onLoaded();
    }
  };

  private setDataTimeDeltaInner = (
    sourceIds: (FeatureType | "tracklines")[], 
    timeDelta: TimeDelta,
    onLoaded?: OnLoaded,
  ): void => {

    const finishedSources = Object.fromEntries(sourceIds.map(src => [src, false]));

    for (const sourceId of sourceIds) {
      this._timeDeltaMap[sourceId] = timeDelta;

      const overallOnLoaded = function() {
        const prevStatus = finishedSources[sourceId];
        finishedSources[sourceId] = true;

        // avoid checking if we got called a 2nd time for the same source
        if (prevStatus === false && Object.values(finishedSources).every(done => done)) {
          onLoaded && onLoaded();
        }
      } as OnLoaded;


      if (sourceId === "tracklines") {
        this.handleTrackTimeDeltaChange(timeDelta, overallOnLoaded);
      } else {
        this.handleDataTimeDeltaChange(sourceId, timeDelta, overallOnLoaded);
      }
    }

    // if we didnt actually need to do anything, make sure we dont indicate we're loading
    if (sourceIds.length === 0) {
      onLoaded && onLoaded();
    }
  };

  public setDataTimeDelta = debounce(this.setDataTimeDeltaInner, 200, { trailing: true });

  public cleanUp = (): void => {
    for (const trackId in this._tracklineListeners) {
      this._tracklineListeners[trackId]();
    }

    for (const dataId in this._dataListeners) {
      const listenerUnsub = this._dataListeners[dataId];
      if (typeof listenerUnsub === "function") {
        listenerUnsub();
      }
    }

    if (this._sightingStateInterval !== null) {
      clearInterval(this._sightingStateInterval);
      this._sightingStateInterval = null;
    }

    this._dataCache.cleanUp();
  };
}

export default MapManager;

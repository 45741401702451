import type { Map } from "mapbox-gl";


export interface SMADate {
  day: number;
  month: number;
};

const nowSMADate = () => dateToSMADate(new Date(Date.now()));

export function buildSMAVisibilityCallback(map: Map): () => void {
  let lastUpdateTime = nowSMADate();

  updateSMAVisibility(map, lastUpdateTime);

  return function() {
    let current = nowSMADate();

    if (!sameSmaDate(lastUpdateTime, current)) {
      updateSMAVisibility(map, current);
      lastUpdateTime = current;
    }
  };
}

function sameSmaDate(a: SMADate, b: SMADate): boolean {
  return a.day == b.day && a.month == b.month;
}

function dateToSMADate(date: Date): SMADate {
  return {
    day: date.getDate(),
      
    // getMonth returns a 0-based month (i.e january = 0),
    // but the 'months' in the SMA layers start from 1.
    month: date.getMonth() + 1,
  };
}


export function updateSMAVisibility(map: Map, smaDate: SMADate) {
  const { day, month } = smaDate;
  
  map.setFilter("NOAA-SMA", [
    "all",
    // ensure fields exist
    ["has", "st_mo"],
    ["has", "st_day"],
    ["has", "end_mo"],
    ["has", "end_day"],
    // then 
    ["<=", ["get", "st_mo"], month],
    ["<=", ["get", "st_day"], day],
    ["<=", month, ["get", "end_mo"]],
    ["<=", day, ["get", "end_day"]],
  ]);
}
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import {
  Row,
  Dropdown,
  Image,
  Spinner,
  Form,
  FormCheck,
  Collapse,
  Fade,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import MapNav from "../nav/MapNav";

import L from "../../layers/groups";

import { FeatureType, LegendIcon, LegendItem, MapMenuConfig } from "../../misc/Types";
import { isNonEmptyRecord, stringSortFactory } from "../../misc/Utils";
import type MapManager from "../../map/MapManager";

import StateProvider from "../../provider/StateProvider";
import MapControlWrapper from "../../map/MapControlWrapper";
import FallbackOnError from "../misc/FallbackOnError";
import SightingAgeLegend from "./SightingAgeLegend";
import { Filter, Layer, MiscLayer } from "../../layers/LayerTypes";
import { BoundedRangeSlider, Values } from "../misc/BoundedRangeSlider";

import { useLoadTracker } from "../../hooks/useLoadTracker";

import { range } from "lodash";
import { IndexedTimeOffset, TimeDelta, Seconds, makeIndexedTimeDeltas, TimeOffset } from "../../misc/TimeDelta";


const EXPAND_LESS_ICON = new URL("/public/static/icons/expand_less.svg", import.meta.url);
const EXPAND_MORE_ICON = new URL("/public/static/icons/expand_more.svg", import.meta.url);

export type MapMenuProps = React.PropsWithoutRef<{
  mapManager: MapManager;
  legendSections: MapMenuConfig;
}>;


export interface TimeDeltaRanges {
  [FeatureType.Sightings]: TimeDelta<IndexedTimeOffset>;
  tracklines: TimeDelta<IndexedTimeOffset>;
}

const stringSorter = stringSortFactory({ preproc: (s: string) => s.toLocaleLowerCase() });


const TIME_DELTAS: IndexedTimeOffset[] = makeIndexedTimeDeltas([
  { label: "Current", delta: 0 as Seconds },
  TimeOffset.fromMinutes(1),
  TimeOffset.fromMinutes(5),
  TimeOffset.fromMinutes(10),
  TimeOffset.fromMinutes(15),
  TimeOffset.fromMinutes(30),
  TimeOffset.fromHours(1),
  TimeOffset.fromHours(2),
  TimeOffset.fromHours(6),
  TimeOffset.fromDays(1),
  TimeOffset.fromDays(2),
  TimeOffset.fromDays(3),
  TimeOffset.fromDays(4),
  ...range(5, 180, 5).map(TimeOffset.fromDays)
]);


const DEFAULT_TIME_DELTAS: TimeDeltaRanges = {
  [FeatureType.Sightings]: { new: null, old: TIME_DELTAS[5], },
  tracklines: { new: null, old: TIME_DELTAS[4], }
};

export interface LegendItemProps {
  item: LegendItem;
  showExpandIcon?: boolean;
}

export interface LegendItemToggleProps extends LegendItemProps {
  greyOut?: boolean;
}


function InlineIcon(props: { icon: LegendIcon }): JSX.Element {
  const src = useMemo(() => {
    switch (props.icon.type) {
      case "svg": 
        return "data:image/svg+xml;charset=utf-8;base64," + btoa(props.icon.svg);
      case "img":
        return props.icon.src;
    }
  }, [props]);
  
  return (
    <Image 
      className="mr-1"
      src={src} 
      height={props.icon.height ?? 25} 
      width={props.icon.width ?? 25}
    />
  );
}

const LegendItemRow = (props: { 
  className?: string,
  greyOut?: boolean,
  item: LegendItem
}): JSX.Element => {
  const { item } = props;
  const icons = useMemo(() => {
    return item.icons.map((icon, idx) => <InlineIcon key={idx} icon={icon}/>);
  }, [props.item]);

  const classes = ["map-menu-legend-icon"];
  if (typeof props.className === "string") {
    classes.push(props.className);
  }

  if (props.greyOut === true) {
    classes.push("map-menu-legend-item-grey-out");
  }

  return (
    <div className={classes.join(" ")}>
      <div className="map-menu-legend-icon-images">
        {icons}
      </div>
      <span className="smaller-text">
        {`- ${item.text}`}
      </span>
    </div>
  );
};

interface LegendItemDropdownProps {
  baseItem: JSX.Element;
  dropdownItems: JSX.Element[];
}

const LegendItemDropdown = React.memo((props: LegendItemDropdownProps): JSX.Element => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  if (props.dropdownItems.length === 0) {
    return props.baseItem;
  } else if (props.dropdownItems.length === 1) {
    return props.dropdownItems[0];
  } else {
    return (
      <React.Fragment>
        <div
          onClick={() => setCollapseOpen(o => !o)}
          aria-controls=""
          aria-expanded={collapseOpen}
          className='map-menu-legend-item map-menu-legend-toggle smaller-text'
        >
          {props.baseItem}
          <Image src={collapseOpen ? EXPAND_LESS_ICON.href : EXPAND_MORE_ICON.href}
            className="float-right"
          />
        </div>
        <Fade in={collapseOpen}>
          <Collapse in={collapseOpen}>
            <div>
              {props.dropdownItems}
            </div>
          </Collapse>
        </Fade>
      </React.Fragment>
    );
  }
});

LegendItemDropdown.displayName = "LegendItemDropdown";

interface ToggleProps {
  onChange: (vis: boolean) => void;
  label?: string;
  tooltip?: string;
  checked?: boolean;
  checkId: string;
}

export interface LegendSectionProps {
  primaryToggle: ToggleProps;
  legendSection: string;
  legendEntries: Record<string, LegendItem>;
  topDivider?: boolean;
}


function isValidDisplayString(x: unknown): x is string {
  return typeof x === "string" && x.length > 0;
}


const ToggleReactive = React.forwardRef((
  props: ToggleProps,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ref: React.ForwardedRef<any>
): null | JSX.Element => {
  const { onChange, label, checkId, checked } = props;

  return (
    <FormCheck ref={ref} type='switch' className='px-0 pb-0 pt-1 mr-0' inline>
      {
        isValidDisplayString(label)
          ? <FormCheck.Label className="smaller-text mx-1">
            <span>{label}</span>
          </FormCheck.Label>
          : null
      }
      <FormCheck.Input
        className="pull-right ml-1 mr-0"
        id={checkId}
        checked={checked ?? true}
        onChange={(e) => onChange(e.currentTarget.checked)}
      />
    </FormCheck>
  );
});



ToggleReactive.displayName = "ToggleReactive";


const ToggleControlled = React.forwardRef((
  props: ToggleProps,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ref: React.ForwardedRef<any>
): null | JSX.Element => {
  const { onChange, label, checkId, checked } = props;

  const [isChecked, setIsChecked] = useState(checked ?? true);

  useEffect(() => onChange(isChecked), [isChecked]);

  return (
    <FormCheck ref={ref} type='switch' className='px-0 pb-0 pt-1 mr-0' inline>
      {
        isValidDisplayString(label)
          ? <FormCheck.Label className="smaller-text mx-1">
            <span>{label}</span>
          </FormCheck.Label>
          : null
      }
      <FormCheck.Input
        className="pull-right ml-1 mr-0"
        id={checkId}
        checked={isChecked}
        onChange={() => setIsChecked(c => !c)}
      />
    </FormCheck>
  );
});

ToggleControlled.displayName = "ToggleControlled";


function Toggle(props: ToggleProps & { reactive?: boolean }): null | JSX.Element {
  const { tooltip, reactive, ...rest } = props;

  if (isValidDisplayString(tooltip)) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 400 }}
        overlay={(props) => (
          <Tooltip {...props} id={tooltip}>
            {tooltip}
          </Tooltip>
        )}
      >
        { 
          reactive 
            ? <ToggleReactive {...rest}/>
            : <ToggleControlled {...rest}/>
        }
      </OverlayTrigger>
    );
  } else {
    return reactive
      ? <ToggleReactive {...rest} />
      : <ToggleControlled {...rest} />;
  }
}



function GenericLegendSection(
  props: React.PropsWithoutRef<LegendSectionProps & { mapId: string }>
): null | JSX.Element {
  const section = useMemo(() => props.legendSection, [props.legendSection]);
  const legendItems = useMemo(() => props.legendEntries, [props.legendEntries]);

  const primaryToggle =
    useMemo(() => props.primaryToggle, [props.primaryToggle]);

  const sortedItems = useMemo(() => {
    const entries = Object.entries(legendItems);
    
    return entries.sort((a, b) => {
      if (a[0] === props.mapId) {
        return -1;
      } else if (b[0] === props.mapId) {
        return 1;
      } else {
        return stringSorter(a[0], b[0]);
      }
    });
  }, [legendItems, props.mapId]); 

  return (
    <div key={`${section}`}>
      {
        props.topDivider === true
          ? <Dropdown.Divider className='p-0 mt-0 mx-0 mb-0'/>
          : null
      }
      <div className='m-0 p-0 h-auto d-flex flex-row flex-nowrap justify-content-between m-0 p-0 align-self-center align-items-center'>
        <b className='capitalize dropdown-header p-0 m-0'>
          {section}
        </b>
        <div className="d-flex flex-column justify-content-start h-auto">
          <Toggle {...primaryToggle}/>
        </div>
      </div>
      {
        sortedItems.map(([key, item]) => (
          <LegendItemRow 
            key={key}
            item={item} 
          />
        ))
      }
    </div>
  );
}

interface SightingSectionProps {
  header: string;
  layer: Layer;
  mapManager: MapManager;
  primaryToggle: ToggleProps;
  baseEntry: LegendItem;
  basePriorityEntry: LegendItem;
  clientEntries: Record<string, LegendItem>;
  topDivider?: boolean;
}

const SightingSection = (props: SightingSectionProps): JSX.Element => {
  const [clientEnabled, setClientEnabled] = useState<boolean>(true);
  const [sharedEnabled, setSharedEnabled] = useState<boolean>(true);
  const [lastToggled, setLastToggled] = useState<"shared" | "client" | null>(null);

  const [baseEntry, sortedClientEntries] = useMemo(() => {
    const clientSpecific = props.clientEntries[props.mapManager.mapDocument.id];
    
    if (clientSpecific) {
      return [
        <div key={0}>  
          <LegendItemRow key={0} item={props.baseEntry} greyOut={!sharedEnabled}/>
          <LegendItemRow key={1} item={props.basePriorityEntry} greyOut={!sharedEnabled} />
          <LegendItemRow 
            key={props.mapManager.mapDocument.id}
            item={clientSpecific}
            greyOut={!clientEnabled}
          />
        </div>,
        [],
      ];
    } else {
      const entries = Object.entries(props.clientEntries);
  
      const sorted = entries.sort((a, b) => {
        if (a[0] === props.mapManager.mapDocument.id) {
          return -1;
        } else if (b[0] === props.mapManager.mapDocument.id) {
          return 1;
        } else {
          return stringSorter(a[0], b[0]);
        }
      });
  
      const clientEntries = sorted.map(entry => { 
        let greyOut = false;
        if (!clientEnabled) {
          greyOut = props.mapManager.mapDocument.id === entry[0];
        } else if (!sharedEnabled) {
          greyOut = props.mapManager.mapDocument.id !== entry[0];
        }
  
        return (
          <LegendItemRow 
            key={entry[0]}
            item={entry[1]}
            greyOut={greyOut} 
          />
        );
      }); 

      return [
        <div key={0}>
          <LegendItemRow item={props.baseEntry} />
          <LegendItemRow item={props.basePriorityEntry} />
        </div>,
        clientEntries,
      ];
    }
  }, [props.clientEntries, props.mapManager, clientEnabled, sharedEnabled]);

  useEffect(() => {
    // if both are 'enabled', remove any filtering
    if (sharedEnabled && clientEnabled) {
      props.mapManager.setSightingClientFilter(props.layer, null);
    } else if (sharedEnabled && !clientEnabled) {
      props.mapManager.setSightingClientFilter(props.layer, {
        type: Filter.Exclude,
        client: props.mapManager.mapDocument.id,
      });
    } else if (!sharedEnabled && clientEnabled) {
      props.mapManager.setSightingClientFilter(props.layer, {
        type: Filter.Include,
        client: props.mapManager.mapDocument.id,
      });
    } else {
      // force one back on if both are disabled
      switch (lastToggled) {
        case "client":
          setSharedEnabled(true);
          break;
        case "shared":
          setClientEnabled(true);
          break;
      }
    }
  }, [clientEnabled, sharedEnabled, lastToggled, props.mapManager]);

  const buildToggleHandler = useCallback((
    button: "shared" | "client",
    fn: (x: boolean) => void
  ): (x: boolean) => void => {
    return function(b: boolean) { 
      setLastToggled(button);
      fn(b);
    };
  }, []);

  return (
    <div key="stations">
      {
        props.topDivider === true
          ? <Dropdown.Divider className='p-0 mt-0 mx-0 mb-0' />
          : null
      }
      <div className='m-0 p-0 h-auto d-flex flex-row flex-nowrap justify-content-between m-0 p-0 align-self-center align-items-center'>
        <b className='capitalize dropdown-header p-0 m-0'>
          {props.header}
        </b>
        <div className="d-flex flex-column justify-content-start h-auto">
          <Toggle {...props.primaryToggle} />
        </div>
      </div>
      <Form className="my-1 mx-0">
        <div className="d-flex flex-row justify-content-between align-self-center">
          <span className="smaller-text">
            Show {props.mapManager.mapDocument.displayName} Sightings
          </span>
          <Toggle
            reactive
            checked={clientEnabled}
            checkId="client-sightings"
            onChange={buildToggleHandler("client", setClientEnabled)}
          />
        </div>
        <div className="d-flex flex-row justify-content-between align-self-center">
          <span className="smaller-text">
            Show Shared Sightings
          </span>
          <Toggle
            reactive
            checked={sharedEnabled}
            checkId="shared-sightings"
            onChange={buildToggleHandler("shared", setSharedEnabled)}
          />
        </div>
      </Form>
      <LegendItemDropdown 
        baseItem={baseEntry}
        dropdownItems={sortedClientEntries}
      />
    </div>
  );
};



export interface StationSectionProps {
  primaryToggle: ToggleProps;
  baseEntry: LegendItem;
  clientEntries: Record<string, LegendItem>;
  topDivider?: boolean;
  mapId: string;
}



const StationSection = React.memo((
  props: React.PropsWithoutRef<StationSectionProps>
): JSX.Element => {
  const sortedClientEntries = useMemo(() => {
    const entries = Object.entries(props.clientEntries);
    
    const sorted = entries.sort((a, b) => {
      if (a[0] === props.mapId) {
        return -1;
      } else if (b[0] === props.mapId) {
        return 1;
      } else {
        return stringSorter(a[0], b[0]);
      }
    });

    return sorted.map(entry => <LegendItemRow key={entry[0]} item={entry[1]} />); 
  }, [props.clientEntries, props.mapId]);

  return (
    <div key="stations">
      {
        props.topDivider === true
          ? <Dropdown.Divider className='p-0 mt-0 mx-0 mb-0' />
          : null
      }
      <div className='m-0 p-0 h-auto d-flex flex-row flex-nowrap justify-content-between m-0 p-0 align-self-center align-items-center'>
        <b className='capitalize dropdown-header p-0 m-0'>
          Stations
        </b>
        <div className="d-flex flex-column justify-content-start h-auto">
          <Toggle {...props.primaryToggle} />
        </div>
      </div>
      <LegendItemDropdown 
        baseItem={<LegendItemRow item={props.baseEntry}/>}
        dropdownItems={sortedClientEntries}
      />
    </div>
  );
});

StationSection.displayName = "StationSection";


const MapMenuInner = (props: MapMenuProps): JSX.Element => {
  // const { admin } = useStateContext();
  const { mapManager, legendSections } = props;
  const [initAgeSet, setInitAgeSet] = useState(false);

  const loadTracker = useLoadTracker();

  const [legendElements, setLegendElements] = useState<null | JSX.Element[]>(null);

  const [layerTimeDeltas, setLayerTimeDeltas] = useState<TimeDeltaRanges>(DEFAULT_TIME_DELTAS);

  const handleSliderChangeFactory = (
    dataType: "tracklines" | FeatureType.Sightings | FeatureType.Ellipses | FeatureType.BearingLines
  ): (values: Values) => void => {
    const pairedDataTypes: FeatureType[] = [];
    if (dataType === FeatureType.Sightings) {
      pairedDataTypes.push(FeatureType.Ellipses);
      pairedDataTypes.push(FeatureType.BearingLines);
    }

    return (values: Values) => {
      
      const newTimeDelta: TimeDelta = { 
        // strip out a 0 delta so we avoid querying with it later on
        new: values.low === 0 ? null : TIME_DELTAS[values.low],
        old: TIME_DELTAS[values.high],
      };
      
      console.log({ td_len_minus_1: TIME_DELTAS.length - 1, newTimeDelta, ...values });
    
      if (newTimeDelta.old) {
        setLayerTimeDeltas(c => {
          const updatedRec = { ...c, [dataType]: newTimeDelta };
          for (const pairedDataType of pairedDataTypes) {
            updatedRec[pairedDataType] = newTimeDelta;
          }

          return updatedRec;
        });

        mapManager.setDataTimeDelta(
          [dataType, ...pairedDataTypes],
          newTimeDelta,
          loadTracker.startJob(),
        );
      } else {
        console.error(
          `one side of the TimeDelta not found: ${newTimeDelta} for dataType: ${dataType}`
        );
      }
    };
  };

  useEffect(() => {
    const elements: JSX.Element[] = [];
    let idx = 0;

    function onChangeFactory(section: Layer): (newVis: boolean) => void {
      if (!L.LAYER_TO_GROUP[section]) {
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        return function() { };
      }
      
      return function(newVis: boolean): void {
        let initVis: null | boolean;
        for (const layer of L.LAYER_TO_GROUP[section].iterLayers()) {
          initVis = mapManager.getLayerVisibility(layer.layerId);

          if (newVis !== initVis) {
            mapManager.setLayerVisibility(layer.layerId, newVis);
          }
        }
      };
    }


    for (const [section, sectionEntries] of Object.entries(legendSections)) {
      
      if (!isNonEmptyRecord(sectionEntries)) {
        continue;
      }
      
      if (section === FeatureType.Sightings || section === MiscLayer.BirdSightings) {
        const casted = sectionEntries as MapMenuConfig["sightings"];

        const primaryToggle: ToggleProps = {
          onChange: onChangeFactory(section),
          checkId: section,
          tooltip: "Toggle layer visibility"
        };

        const header = section === FeatureType.Sightings
          ? "Sightings"
          : "Avian Sightings";

        elements.push(
          <SightingSection
            key={section}
            layer={section}
            header={header}
            topDivider={idx !== 0}
            mapManager={mapManager}
            primaryToggle={primaryToggle}
            baseEntry={casted.base}
            basePriorityEntry={casted.basePriority}
            clientEntries={casted.dropdown}
          />
        );

      } else if (section === FeatureType.Stations) {
        const casted = sectionEntries as MapMenuConfig["stations"];

        const primaryToggle: ToggleProps = {
          onChange: onChangeFactory(FeatureType.Stations),
          checkId: section,
          tooltip: "Toggle layer visibility"
        };

        elements.push(
          <StationSection 
            key={section}
            topDivider={idx !== 0}
            primaryToggle={primaryToggle} 
            mapId={mapManager.mapDocument.id}
            baseEntry={casted.base}
            clientEntries={casted.dropdown}
          />
        );
      } else {
        const layers = new Set<Layer>();

        const legendItems = Object.entries(sectionEntries) as [string, LegendItem][];

        for (const entry of legendItems) {
          entry[1].layers.forEach(layer => layers.add(layer));
        }

        const innerHandlers = [...layers.values()].map(onChangeFactory);
        const primaryToggle: ToggleProps = {
          onChange: function(newVis: boolean) {
            for (const inner of innerHandlers) {
              inner(newVis);
            }
          },
          checkId: section,
          tooltip: "Toggle layer visibility"
        };

        elements.push(
          <GenericLegendSection key={idx}
            mapId={mapManager.mapDocument.id}
            primaryToggle={primaryToggle}
            legendEntries={sectionEntries as Record<string, LegendItem>}
            legendSection={section}
            topDivider={idx !== 0}
          />
        );
      }   

      idx += 1;
    }

    setLegendElements(elements);
  }, []);


  useEffect(() => {
    if (!initAgeSet && mapManager.mapInitialized) {
      mapManager.setDataTimeDelta(
        ["tracklines"],
        layerTimeDeltas.tracklines ?? DEFAULT_TIME_DELTAS.tracklines
      );


      mapManager.setDataTimeDelta(
        [FeatureType.Sightings, FeatureType.Ellipses, FeatureType.BearingLines],
        layerTimeDeltas.sightings ?? DEFAULT_TIME_DELTAS.sightings
      );

      setInitAgeSet(true);
    }

  }, [mapManager.mapInitialized]);


  return (
    <Dropdown className="w-auto">
      <Dropdown.Toggle className="map-menu-toggle w-auto py-0" variant="success">
        <div className='grid-overlap ml-2'>
          <Row style={{ visibility: legendElements === null ? "visible" : "hidden" }}
            className='justify-content-md-center center grid-overlap-item'>
            <Spinner animation="border" as='span' variant='primary'>
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Row>
          <Row style={{ visibility: legendElements === null ? "hidden" : "visible" }}
            className='justify-content-md-center center grid-overlap-item d-flex flex-row'>
            <span className="pr-0">Legend</span>
            <Spinner 
              style={{ visibility: loadTracker.isLoading ? "visible" : "hidden", boxSizing: "border-box" }}
              animation="border" size="sm" as="span" className="px-0" variant='light'>
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Row>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align='end' className='map-menu py-2 px-3'>
        <FallbackOnError message='' componentName='MapMenu' fallback={MapNav} excludeMap={mapManager.mapDocument.id}>
          <Dropdown.ItemText as='div' className='p-0 m-0'>
            {legendElements}
          </Dropdown.ItemText>
          <Dropdown.Divider className='p-0 my-0'/>
          <b className="dropdown-header p-0 m-0">
            Controls
          </b>
          <Dropdown.ItemText as='div' className='p-0 m-0'>
            <div className='d-flex mx-0 mt-0 mb-1 p-0 w-auto flex-row flex-nowrap justify-content-between align-items-center align-self-center'>
              <span className='mr-2 smaller-text'>
                Filter Sightings:
              </span>
            </div>
            <BoundedRangeSlider className='w-100 py-2 m-0'
              minGap={1}
              min={0}
              max={TIME_DELTAS.length - 1}
              values={{ 
                high: layerTimeDeltas[FeatureType.Sightings].old.index,
                low: layerTimeDeltas[FeatureType.Sightings].new?.index ?? 0
              }}
              label={(index) => TIME_DELTAS[index].label}
              onChange={handleSliderChangeFactory(FeatureType.Sightings)}/>
            <SightingAgeLegend timeDelta={layerTimeDeltas[FeatureType.Sightings] ?? DEFAULT_TIME_DELTAS[FeatureType.Sightings]}/>
            <Dropdown.Divider className='p-0 my-1'/>
            <div className='d-flex mx-0 my-1 p-0 w-auto flex-row flex-nowrap justify-content-between align-items-center align-self-center'>
              <span className='mr-2 smaller-text'>
                Filter Tracklines:
              </span>
            </div>
            <BoundedRangeSlider className='w-100 py-2 m-0'
              min={0}
              minGap={1}
              max={TIME_DELTAS.length - 1}
              values={{
                high: layerTimeDeltas.tracklines.old.index,
                low: layerTimeDeltas.tracklines.new?.index ?? 0,
              }}
              label={(index) => TIME_DELTAS[index].label}
              onChange={handleSliderChangeFactory("tracklines")}/>
          </Dropdown.ItemText>
        </FallbackOnError>
      </Dropdown.Menu>
    </Dropdown>
  );
};

class MapMenu extends MapControlWrapper {
  constructor(props: MapMenuProps) {
    super(
      <StateProvider>
        <div className="w-auto d-flex flex-row flex-nowrap justify-content-between align-items-strech align-self-strech">
          <MapNav className="mr-2 h-100" excludeMap={props.mapManager.mapDocument.id}/>
          <MapMenuInner {...props}/>
        </div>
      </StateProvider>
    );
  }
}

export default MapMenu;
